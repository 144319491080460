<template>
  <div class="homeBox">
      <div class="top">
          <router-view></router-view>
      </div>
  </div>
</template>

<script>
import menuBottom from '@/views/components/business/menuBottom'
export default {
  name: "Home",
  components: {
    menuBottom
  },
  data() {
    return {
    };
  },
  computed: {
    // menuList() {
    //   console.log(store.state.user.menulist)
    //   return store.state.user.menulist
    // }
  },
  created() {
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
.homeBox {
  width: 100vw;
  height: 100vh;
  // .menu{
  //   width: 100vw;
  //   height: 66rem;
  // }
  // .top{
  //   width: 100vw;
  //   height: calc(100vh - 66rem);
  // }
}

/*通过css隐藏文字，当菜单被折叠的时候，会多一个el-menu--collapse的类，所以
通过把这个类下的对应层级里面的标题文字和对应小箭头隐藏起来*/
/deep/ .el-menu--collapse .el-submenu .el-submenu__title span {
  display: none;
}
/deep/ .el-menu--collapse .el-submenu .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}
</style>
